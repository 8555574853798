import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Debug, NotificationService, ProfileService } from '@app/core';
import { ApiErrorOptions, ApiErrorService } from '@app/core/api-error.service';
import { AccountService, PasswordChangeTokenRequest, SetPasswordRequest } from '../account.service';

@Component({
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  formSubmitted = false;
  displayForm = false;
  isPasswordValid = false;
  emailLocked = false;
  loading = false;
  private token: string; // PW reset token

  passwordValidation: PasswordValidation = {
    upper: false,
    lower: false,
    number: false,
    length: false,
    matches: false,
  };

  constructor(
    private readonly form: FormBuilder,
    private readonly error: ApiErrorService,
    private readonly api: AccountService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly notify: NotificationService,
    private readonly profile: ProfileService
  ) { }

  async ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.createResetForm();

    this.loading = true;
    var accessToken = await this.validateToken();
    this.loading = false;

    if (accessToken != null) {
      this.displayForm = true;
    } else {
      await this.redirectToReset();
    }
  }

  protected async onSubmit() {
    if (!this.passwordForm.valid) {
      return;
    }

    // get the access token and verify that it is still active
    const accessToken = await this.validateToken();
    if (accessToken == null) {
      await this.redirectToReset();
      return;
    }

    const setDetails: SetPasswordRequest = {
      email: this.passwordForm.get('email').value,
      password: this.passwordForm.get('passwordOne').value,
      token: this.token
    };
    this.formSubmitted = true;

    try {
      const response = await this.api.setPassword(setDetails, accessToken.accessToken);
      Debug.debug('[SetPassword:setPassword] Response:', response);
    } catch (e) {
      const error = e as HttpErrorResponse;
      let message = 'Error setting your new password, please contact Customer Service for assistance';
      if (error.error == 'password_reused') {
        message = 'This password has already been used. Please enter a unique password';
      }
      const errorOptions: ApiErrorOptions = {
        message: message,
        title: 'Password update failed'
      };
      this.formSubmitted = false;
      this.error.processError(e, 'setPassword', errorOptions);
      return;
    }

    Debug.debug('[SetPassword] Password updated successfully');
    this.displayForm = false;
  }

  protected validatePassword() {
    const passwordOne = this.passwordForm.get('passwordOne').value;
    const passwordTwo = this.passwordForm.get('passwordTwo').value;

    var upper = /.*[A-Z].*/;
    var lower = /.*[a-z].*/;
    var number = /.*[0-9].*/;
    var length = /.{8,}/;

    this.passwordValidation = {
      upper: upper.test(passwordOne),
      lower: lower.test(passwordOne),
      number: number.test(passwordOne),
      length: length.test(passwordOne),
      matches: passwordOne && passwordTwo == passwordOne,
    };

    this.isPasswordValid = Object.values(this.passwordValidation).every(x => x);
  }

  private createResetForm() {
    const email = this.route.snapshot.paramMap.get('email');
    this.emailLocked = String.isNotEmpty(email);
    this.passwordForm = this.form.group({
      email: [email, [Validators.required, Validators.email]],
      passwordOne: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^[-A-Za-z0-9#?!@$%^&+=:;'"*,._`~/|\\ ]+$/),
      ]],
      passwordTwo: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^[-A-Za-z0-9#?!@$%^&+=:;'"*,._`~/|\\ ]+$/),
      ]],
    });
  }

  private async validateToken() {
    const request: PasswordChangeTokenRequest = {
      token: this.token
    };

    try {
      const response = await this.api.validateToken(request);
      Debug.debug('[SetPassword:validateToken] Response:', response);
      return response;
    } catch (e) {
      Debug.error('Token validation failure', e);
      return null;
    }
  }

  private async redirectToReset() {
    let message = 'The password reset link you clicked has expired. Please fill out the form again to receive a new link.\n\n';
    message += 'Closing this modal will return you to the password reset page.';

    await this.notify.showModalMessage(
      'Link expired',
      message,
    );

    const email = this.passwordForm?.get('email').value ?? this.route.snapshot.paramMap.get('email');
    let route = '/reset-password';
    if (email && email.length) {
      route += encodeURIComponent(email);
    }
    return this.router.navigateByUrl(route);
  }

  redirectToLogin() {
    this.profile.redirectToLogin(false, true);
  }
}

interface PasswordValidation {
  upper: boolean;
  lower: boolean;
  number: boolean;
  length: boolean;
  matches: boolean;
}