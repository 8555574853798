import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import saveAs from 'file-saver';
import { firstValueFrom, of, switchMap, timer } from 'rxjs';
import { Debug } from '../debug';
import { MessageModalButtonMode, MessageModalButtonOptions, NotificationService, ToastType } from '../notification-service';
import { AuthHttpInterceptor } from '../profile';
import { SessionService } from '../storage/session.service';
import { ActiveDownloadSessions, DownloadOptions, QueueStatus } from './download.interfaces';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  // Global status object. Each property will hold the status info of a single polling operation (a queue)
  private activeDownloads: ActiveDownloadSessions = {};
  private readonly defaultPollingAttempts = 24;
  private readonly defaultPollingDelay = 5000;

  constructor(
    private readonly http: HttpClient,
    private notify: NotificationService,
    private readonly session: SessionService
  ) {
    const previousActiveDownloads = this.session.getItem<ActiveDownloadSessions>('activeDownloads', {}, true);
    if (Object.keys(previousActiveDownloads).length) {
      Debug.debug('[DownloadService] Loading previous queued downloads', previousActiveDownloads);
      let downloads = 0;
      for (const queue of Object.keys(previousActiveDownloads)) {
        if (previousActiveDownloads[queue]) {
          downloads++;
          this.queueDownload(previousActiveDownloads[queue].link, queue, previousActiveDownloads[queue].options);
        }
      }
      if (downloads) {
        this.notify.showToast('Previous queued downloads were detected. Resuming download(s).', 'Resuming queued download', ToastType.Info);
      }
    }
  }

  isDownloadQueued(queueName: string) {
    return !!this.activeDownloads[queueName.toLowerCase()];
  }

  // Prevent outside components from directly changing this property
  // Cannot be a standard getter, since it accepts an argument
  getStatus(queueName: string) {
    return this.activeDownloads[queueName.toLowerCase()]?.status?.statusObject ?? null;
  }

  private setStatus(queueName: string, status: QueueStatus | null) {
    this.activeDownloads[queueName].status.statusObject = status;
  }

  pollingAttempts(queueName: string) {
    // Default to 0 just in case someone tries to do math with it
    return this.activeDownloads[queueName.toLowerCase()]?.status?.pollingAttempts ?? 0;
  }

  private incrementPollingAttempts(queueName: string) {
    this.activeDownloads[queueName].status.pollingAttempts++;
    this.saveSession();
  }

  private resetPollingAttempts(queueName: string, startingValue = 0) {
    this.activeDownloads[queueName].status.pollingAttempts = startingValue;
    this.saveSession();
  }

  private addActiveDownload(queueName: string, link: string, options: DownloadOptions, status: QueueStatus) {
    this.activeDownloads[queueName] = { link, options, status };
  }

  private removeActiveDownload(queueName: string) {
    this.activeDownloads[queueName] = null;
  }

  private saveSession() {
    this.session.setItem('activeDownloads', this.activeDownloads, true);
  }

  /**
   * Converts a string representation of a Mime Type into the corresponding file extension
   * @param mimeType string: Mime Type to convert
   * @returns string: file extension that matches the input
   */
  getExtensionsFromMimeType(mimeType: string) {
    switch (mimeType) {
      case 'application/zip':
        return '.zip';
      case 'application/pdf':
        return '.pdf';
      case 'application/vnd.ms-excel':
        return '.xls';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return '.xlsx';
      case 'application/vnd.ms-outlook':
        return '.msg';
      default:
        return '.bin';
    }
  }

  /**
   * Uses a polling link for a queued download and begins polling
   * Will initiate download upon completion
   * @param [link] string: URL to the download
   * @param [options] DownloadOptions: Options for various aspects of the download and polling process
   */
  queueDownload(link: string, queueName: string, options?: DownloadOptions) {
    queueName = queueName.toLowerCase(); // Just in case someone doesn't use queue name constants
    if (this.isDownloadQueued(queueName)) {
      this.notify.showToast('A download has already been queued. Either cancel the existing queue or wait until it completes.', 'Download already queued', ToastType.Error);
      return;
    }
    Debug.debug('[DownloadService] queueDownload', `Link: ${link}`);
    // Initialize the queue status property
    const status: QueueStatus = {
      statusObject: options?.pollingOptions?.queueStatusObject || { link }, // Polling is inactive while this is null
      pollingAttempts: 0 // # of polling attempts made. Once it hits `defaultPollingAttempts` it will stop trying
    };
    this.addActiveDownload(queueName, link, options, status);
    this.pollEndpoint(link, queueName, options);
  }

  // Start a delayed observable which pings an endpoint until it either:
  // Sends a file, returns an error, or reaches a retry limit
  private async pollEndpoint(link: string, queueName: string, options?: DownloadOptions) {
    // Wait a bit before sending out the get request (prevents api flooding)
    try {
      const response = await firstValueFrom(
        timer(options?.pollingOptions?.delay || this.defaultPollingDelay, -1)
          .pipe(
            switchMap(() => {
              // Switch to a different Observable response based on env settings
              if (environment?.useLocalData) {
                if (this.pollingAttempts(queueName) < 2) {
                  Debug.log('[DownloadService] pollEndpoint', 'Returning fake file pending status');
                  return of(new HttpResponse<Blob>({ body: new Blob(), status: 204, statusText: 'Using local data' }));
                } else {
                  Debug.log('[DownloadService] pollEndpoint', 'Returning fake (empty) export file');
                  return of(new HttpResponse<Blob>({ body: new Blob(), status: 200, statusText: 'Using local data' }));
                }
              } else {
                Debug.debug('[DownloadService] pollEndpoint', 'Checking file download status', link);
                return this.http.get(
                  link,
                  { responseType: 'blob', observe: 'response', headers: { 'addAuthorization': 'true' } }
                );
              }
            })
          )
      );

      Debug.debug('[DownloadService] pollEndpoint', 'Response:', response);
      // Ignore the response if it's a remnant from a previously cancelled operation
      if (!this.getStatus(queueName) || this.getStatus(queueName) != (options?.pollingOptions?.queueStatusObject || { link })) {
        Debug.debug('[DownloadService] pollEndpoint', 'Ignoring response from cancelled polling. Link:', link);
        return;
      }
      this.incrementPollingAttempts(queueName);
      // Process each response, beginning the download once a 200 response is returned
      if (response?.status == 200) {
        this.cancelQueuedDownload(queueName, false, false);
        const info = response.headers.get('X-Note');
        if (info) {
          const title = 'Download note:';
          this.notify.showToast(info, title, ToastType.Warning);
        }
        this.startBrowserDownload(response, options);
      } else if (this.pollingAttempts(queueName) >= (options?.pollingOptions?.attempts || this.defaultPollingAttempts)) {
        // If the # of attempts run out, ask if the user wants to keep waiting
        try {
          const message = 'Your queued download is still being processed. Do you want to continue waiting or cancel?';
          await this.notify.showModalMessage('Download still pending', message, {
            mode: MessageModalButtonMode.ConfirmCancel,
            cancelTitle: 'Cancel',
            confirmTitle: 'Keep waiting'
          });
          // Each time extension will ask again in half the original time
          this.resetPollingAttempts(queueName, Math.ceil(this.pollingAttempts(queueName) / 2));
          this.pollEndpoint(link, queueName, options);
        } catch {
          // User chose to abort the download
          this.cancelQueuedDownload(queueName);
        }
      } else {
        // Queue up the next polling observable
        this.pollEndpoint(link, queueName, options);
      }
    } catch (e) {
      // Ignore the response if it's a remnant from a previously cancelled operation
      if (!this.getStatus(queueName) || this.getStatus(queueName) != (options?.pollingOptions?.queueStatusObject || { link })) {
        Debug.debug('[DownloadService] pollEndpoint', 'Ignoring response from cancelled polling. Link:', link);
        return;
      }
      const error = e as HttpErrorResponse;
      Debug.error('[DownloadService] pollEndpoint', `Download failed w/${error.status} error:`, error);

      // If a 410 (Gone) response, inform the user accordingly
      if (error.status == 410) {
        this.cancelQueuedDownload(queueName, false, false); //  No matter the error, the fun is over.. cancel
        if (options?.pollingOptions?.showModalOnError) {
          const title = 'Download failed due to a server error';
          let message = 'A server error occurred while preparing your download. This error has been logged. ';
          message += 'Please try your download again. If you\'re still receiving this error after 1 business day, please contact customer service.';
          this.notify.showModalError(
            title,
            message,
            (environment.production) ? [] : [`Code: ${error.status}`, `Error object: ${JSON.stringify(error) ?? ''})`]
          );
        } else if (options?.pollingOptions?.showToastOnError == null || options?.pollingOptions?.showToastOnError) {
          const title = 'Server issue';
          const message = 'Error downloading queued files. This error has been logged. Please try again.';
          this.notify.showToast(message, title, ToastType.Error);
        }
        // If a 429 (Too many requests) response, inform the user accordingly
      } else if (error.status == 429) {
        const url = error.error['url'];
        const cancelUrl = error.error['cancelUrl'];

        // Does the api support cancel/resume previous downloads?
        if (url || cancelUrl) {
          const title = 'Previous download still pending';
          let message = 'You may only queue one download at a time.';
          message += ' Would you like to continue waiting on your previous download or cancel it and switch to this one?';
          const buttonOptions: MessageModalButtonOptions = {
            mode: MessageModalButtonMode.ConfirmCancel,
            cancelTitle: 'I\'ll wait',
            confirmTitle: 'Cancel and switch'
          };
          try {
            await this.notify.showModalMessage(
              title,
              message,
              buttonOptions
            );
            // Keep waiting
          } catch {
            // Cancel and switch
            this.notify.showToast('Attempting to cancel existing download..', 'Cancel and switch', ToastType.Info);
            const cancelLink = (cancelUrl) ? cancelUrl : `${url}/cancel`;
            try {
              await firstValueFrom(this.http.post(cancelLink, null, AuthHttpInterceptor.AddAuthHttpHeader));
              this.notify.showToast('Switching to new download', 'Cancel and switch', ToastType.Success);
              // Restart the queue
              this.queueDownload(link, queueName, options);
            } catch {
              this.notify.showToast('Cancellation failed. You must wait until the previous download completes before retrying this download', 'Cancel and switch', ToastType.Error);
            }
          }
        } else {
          const title = 'Existing download pending';
          let message = 'You may only queue one download at a time.';
          if (options?.pollingOptions?.showModalOnError) {
            message += ' Wait until the current download has completed or cancel it.';
            this.notify.showModalError(
              title,
              message,
              (environment.production) ? [] : [`Code: ${error.status}`, `Error object: ${JSON.stringify(error) ?? ''})`]
            );
          } else if (options?.pollingOptions?.showToastOnError == null || options?.pollingOptions?.showToastOnError) {
            this.notify.showToast(message, title, ToastType.Warning);
          }
        }
        // If a 500, hard abort and notify of the sad news
      } else if (error.status == 500) {
        this.cancelQueuedDownload(queueName, false, false); //  No matter the error, the fun is over.. cancel
        if (options?.pollingOptions?.showModalOnError) {
          let message = 'A server error occurred while downloading. This error has been logged. ';
          message += 'Please try your download again. If you\'re still receiving this error after 1 business day, please contact customer service.';
          this.notify.showModalError(
            'Error downloading file',
            message,
            (environment.debugLevel == 1) ? [`Code: ${error.status}`, `Error object: ${JSON.stringify(error) ?? ''})`] : []
          );
        } else if (options?.pollingOptions?.showToastOnError == null || options?.pollingOptions?.showToastOnError) {
          const title = 'Server issue';
          const message = 'Error downloading queued file. This error has been logged. Please try again later.';
          this.notify.showToast(message, title, ToastType.Error);
        }

        // Else show a generic error notification
      } else {
        this.cancelQueuedDownload(queueName, false, false); //  No matter the error, the fun is over.. cancel
        if (options?.pollingOptions?.showModalOnError) {
          this.notify.showModalError(
            'Error downloading file',
            error.message,
            (environment.debugLevel == 1) ? [`Code: ${error.status}`, `Error object: ${JSON.stringify(error) ?? ''})`] : []
          );
        } else if (options?.pollingOptions?.showToastOnError == null || options?.pollingOptions?.showToastOnError) {
          const title = 'Download failed';
          const message = 'Error downloading queued file';
          this.notify.showToast(message, title, ToastType.Error);
        }
      }
    }
  }

  async cancelQueuedDownload(queueName: string, showToast = true, sendCancel = true) {
    queueName = queueName.toLowerCase(); // Just in case someone doesn't use queue name constants
    if (this.getStatus(queueName)) {
      if (sendCancel) {
        const cancelUrl = this.activeDownloads[queueName]?.options?.pollingOptions?.cancelUrl;
        const link = this.activeDownloads[queueName]?.link;
        this.setStatus(queueName, null);
        // Send a cancel request (ignore the response, since we don't care if it's supported)
        try {
          if (cancelUrl) {
            await firstValueFrom(this.http.post(cancelUrl, null, AuthHttpInterceptor.AddAuthHttpHeader));
          } else {
            await firstValueFrom(this.http.post(link + '/cancel', null, AuthHttpInterceptor.AddAuthHttpHeader));
          }
        } catch {
          // Don't care if there's an error
        }
      }
      if (showToast) {
        // .onHidden gives the api time to process the cancellation
        await firstValueFrom(this.notify.showToast('Download queue cancelled', '', ToastType.Info).onHidden);
      }
      this.removeActiveDownload(queueName);
    } else {
      if (showToast) {
        this.notify.showToast('Cancellation already in progress', '', ToastType.Info);
      }
    }
    this.saveSession();
  }

  /**
  * Initiates a download in the browser using the body of the provided http response
  * @param response Http response from the api
  * @param options See `StartDownloadOptions`
  * @returns boolean Whether or not the download succeeded
  */
  startBrowserDownload(response: HttpResponse<Blob>, options?: DownloadOptions): boolean {
    Debug.debug('[DownloadService] startBrowserDownload', 'Starting download');
    let success = false;
    if (response.body) {
      let filename = '';
      if (options?.filename) {
        filename = options.filename;
      } else {
        // Attempt to fetch the filename from the api server
        const fileInfo = response.headers.get('Content-Disposition')?.split(';')[1].split('=') ?? [null, null];
        filename = fileInfo[1] || options?.alternateFilename || `${window.document.baseURI.replace(/https?:\/\//ig, '')} - ${new Date()}.zip`;
      }
      if (options?.successMessage) this.notify.showToast(options.successMessage, null, ToastType.Success);
      let contentType = response.headers.get('Content-Type');
      if (!contentType || contentType.toLowerCase() === 'application/octet-stream') {
        const fileExtension = filename.substring(filename.lastIndexOf('.')).toLowerCase();
        switch (fileExtension) {
          case '.pdf':
            contentType = 'application/pdf';
            break;
          case '.xlsx':
            contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset: utf-8';
            break;
          case '.zip':
            contentType = 'application/zip';
            break;
          default:
            contentType = '';
        }
      }
      if (contentType) {
        const fileContent = new Blob([response.body], { type: contentType });
        saveAs(fileContent, filename);
        success = true;
      }
    }
    if (!success) {
      const title = options?.errorTitle || 'File download failed';
      const message = options?.errorMessage || 'The file download failed. Please try again later';
      Debug.error('[DownloadService] startBrowserDownload', title, response);
      this.notify.showModalError(title, message, (environment.production) ? [] : [`Code: ${response.status}`, `Error object: ${JSON.stringify(response) ?? ''})`]);
    }
    return success;
  }
}
