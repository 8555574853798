import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Debug, NotificationService, ToastType, WindowService } from '@app/core';
import { environment } from '@env';
import { EmptyError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorService {
  private readonly defaultOptions: ApiErrorOptions = {
    hiddenCodes: [404],
    showDebug: environment.debugLevel == 1,
    showAllForLocalDev: true
  };

  constructor(
    private readonly notify: NotificationService,
    private readonly windowSvc: WindowService
  ) { }

  /**
   * Determines the type of http error and handles/reports it accordingly
   * @param response HTTP Error response object
   * @param source Name of source method to display in console
   * @param options Overrides and options. See: `ApiErrorOptions` for details
   * @returns Error toast Promise
   */
  processError(exception: HttpErrorResponse | EmptyError | unknown, source = 'processError', options: ApiErrorOptions = {}) {
    const response = exception as HttpErrorResponse;
    options = { ...this.defaultOptions, ...options }; // Override the default options

    if (options.showDebug) {
      Debug.error(`[ApiError] ${source}: ${response.status}`, response);
    }

    const isLocal = this.windowSvc.nativeWindow.location.hostname == 'localhost';
    // Check for overrides
    if (isLocal && options.showAllForLocalDev || !options.hiddenCodes?.includes(response.status)) {
      // Generic error defaults
      let title = 'Information unavailable';
      let message = 'The information you\'re attempting to access was not found';
      let type = ToastType.Error;

      if (response.status == 0) { // Network connection error
        title = 'Network error';
        message = 'The server could not be reached. \nPlease check your internet connection. \nIf your connection is working in other applications, our server may be down. \n\nPlease try again later.';
      } else if (response.status == 403 || response.status == 401) { // Forbidden
        title = 'Permission denied';
        message = 'Your account does not have permission to access this information';
      } else if (response.status == 410) { // Gone
        message = 'The information you\'re attempting to access is no longer available';
      } else if (response.status == 429) { // Too many requests
        title = 'Previous request still pending';
        message = 'There is already a request being processed, please wait for it to complete.';
        type = ToastType.Warning;
      } else if (response.status == 409) { // Conflict
        title = 'Conflict found';
        message = 'The information you\'re attempting to submit has a conflict with existing data';
      } else if (response.status == 500) { // Internal server error
        message = 'There was an error on our end. This error has been logged. \n\n';
        message += 'Please try your request again later. If you\'re still receiving this error after 1 business day, please contact customer service.';
      }

      // Override the default title (if override title provided)
      if (options.title) title = options.title;

      // Override the default message (if override message provided)
      if (options.message) message = options.message;

      // Add the additional message (if provided)
      if (options.additionalMessage) message += `\n\n${options.additionalMessage}`;

      return this.notify.showToast(message, title, type, 10000);
    }
  }
}

export interface ApiErrorOptions {
  title?: string, // Override the default error title (use with caution, as it may be confusing to the user if it doesn't match the message)
  message?: string, // Override the default error message
  additionalMessage?: string, // Add an additional message to the default (or overridden) message
  hiddenCodes?: number[], // Don't display any error messages with the following response codes (unless showAllForLocalDev is true)
  showAllForLocalDev?: boolean, // Display ALL error messages when running from localhost
  showDebug?: boolean, // Show debugging/error info in the console (defaults to true when environment debugLevel is 1)
}
