import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentUpdateService } from '@app/environment-update.service';
import { environment } from '@env';
import { cloneDeep, isEqual } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { maxSatisfying } from 'semver';
import { Directory, DirectoryEntry, FeatureDefinition } from '..';
import { Debug } from '../debug';

@Injectable({
  providedIn: 'root'
})
export class DirectoryService {
  private directory: Readonly<Directory>;
  private cloneCache: { [key: string]: Readonly<FeatureDefinition>; };

  private portalMatch = /https?:\/\/[-\w\d.]+\/portal\//i;
  private aimMatch = /https?:\/\/[-\w\d.]+\/aim\//i;
  private producersMatch = /https?:\/\/[-\w\d.]+\/producers\/api\//i;
  private productsMatch = /https?:\/\/[-\w\d.]+\/products\/api\//i;
  private onboardingMatch = /https?:\/\/[-\w\d.]+\/rps-onboarding-service\/v\d{1}\/?/i;
  private portalClientMatch = /https?:\/\/[-\w\d.]+\/portal-client\//i;

  constructor(
    private readonly devSetting: EnvironmentUpdateService,
    private readonly http: HttpClient
  ) { }

  load() {
    this.cloneCache = {};
    let path = `${environment.directoryApiUri}/directory/${environment.clientId}`;
    if (environment?.useLocalData) {
      Debug.log('Returning local directory-service data');
      path = 'assets/specData/core/directoryService.json';
    }

    const options = {
      headers: {
        'addAuthorization': 'true',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      }
    };

    return this.http.get<Directory>(path, options)
      .pipe(
        map(dir => {
          this.directory = Object.freeze(dir);
          Debug.debug('[Directory Service]', 'Response:', dir);
        }),
        catchError(err => {
          this.directory = Object.freeze({});
          Debug.critical('Directory load failure', err);
          return of(this.directory);
        }),
        shareReplay(1)
      );
  }

  getDefinition(name: string, versionRange: string) {
    let entry: DirectoryEntry;
    if (this.directory) {
      entry = this.directory[name];
    }

    if (entry) {
      const allVersions = Object.keys(entry);
      const bestVersion = maxSatisfying(allVersions, versionRange);

      if (bestVersion) {
        const key = `${name}-${bestVersion}`;
        if (
          this.cloneCache[key] &&
          !(
            this.devSetting.useLocalAimApi ||
            this.devSetting.useLocalPortal ||
            this.devSetting.useLocalProducersApi ||
            this.devSetting.useLocalProductsApi ||
            this.devSetting.useLocalOnboardingApi ||
            this.devSetting.useLocalData
          )
        ) {
          return this.cloneCache[key];
        }

        if (entry[bestVersion]) {
          const clonedEntry = cloneDeep(entry[bestVersion]);
          let result: FeatureDefinition = { ...clonedEntry, name: name, version: bestVersion };
          if (this.devSetting.isLocal()) {
            const resultWithDevUrls = this.applyDevUrls(result);
            let newFeatureDef: FeatureDefinition = { ...resultWithDevUrls, name: result.name, version: result.version };
            if (!isEqual(result, newFeatureDef)) {
              Debug.debug(`[getDefinition] Local services applied to ${name}`, result);
              result = newFeatureDef;
            }
          }

          this.cloneCache[key] = Object.freeze(result);
          return this.cloneCache[key];
        }
      }
      Debug.error(`getDefinition error: Feature "${name}" has no version matching "${versionRange}"`);
    } else {
      Debug.error(`getDefinition error: Feature "${name}" not found`);
    }

    Debug.log(`Returning empty definition for feature "${name}" version ${versionRange}`);
    return { name, version: versionRange };
  }

  // Changes remote urls to local if the corresponding option is set in the dev bar
  private applyDevUrls(targetObject: JsonObject | FeatureDefinition, recursive = true) {
    if (typeof targetObject !== 'object') { return targetObject; }
    let newObject: JsonObject = {};
    Object.keys(targetObject).forEach(feature => {
      if (feature == 'name' || feature == 'version') { // Special properties are skipped no matter what
        newObject[feature] = targetObject[feature];
        return;
      }
      // Only check strings with urls
      if (typeof targetObject[feature] === 'string' && String.contains(targetObject[feature], 'http')) {
        const url: string = targetObject[feature];
        if (this.devSetting.useLocalPortal && url.match(this.portalMatch)) { // Portal UI (MyRPSv2)
          newObject[feature] = url.replace(this.portalMatch, this.devSetting.localPortalApiUri);
        } else if (this.devSetting.useLocalAimApi && url.match(this.aimMatch)) { // AIM api
          newObject[feature] = url.replace(this.aimMatch, this.devSetting.localAimApiUri);
        } else if (this.devSetting.useLocalProducersApi && url.match(this.producersMatch)) { // Producers service
          newObject[feature] = url.replace(this.producersMatch, this.devSetting.localProducersApiUri);
        } else if (this.devSetting.useLocalProductsApi && url.match(this.productsMatch)) { // Products service
          newObject[feature] = url.replace(this.productsMatch, this.devSetting.localProductApiUri);
        } else if (this.devSetting.useLocalOnboardingApi && url.match(this.onboardingMatch)) { // Onboarding service
          newObject[feature] = url.replace(this.onboardingMatch, this.devSetting.localOnboardingApiUri);
        } else if (this.devSetting.isLocal() && url.match(this.portalClientMatch)) { // Portal client links (need to remove the `portal-client` section)
          newObject[feature] = url.replace(this.portalClientMatch, 'http://localhost:4200/');
        } else { // External urls shouldn't be touched
          newObject[feature] = targetObject[feature];
        }
      } else if (typeof targetObject[feature] === 'object' && recursive) { // Nested objects are recursively checked/modified
        newObject[feature] = this.applyDevUrls(targetObject[feature]);
      } else { // Just a normal string/number/boolean
        newObject[feature] = targetObject[feature];
      }
    });
    return newObject;
  }
}
